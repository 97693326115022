import React, { FC } from 'react';
import classNames from 'classnames';
import { ObjectModelProps } from '../../models';
const deerSrc = `https://cdn.grass-animals.ru/deer.mp4`;
const deerTalkSrc = `https://cdn.grass-animals.ru/deerTalk.mp4`;

export const DeerVideo: FC<ObjectModelProps> = ({ isAudioPlayed }) => {
  const className = 'videoModel__wrapper videoModel__wrapper--deer';

  return (
    <div className={className}>
      <div
        className={classNames('videoModel', { 'videoModel--noTalk': !isAudioPlayed })}
        dangerouslySetInnerHTML={{
          __html: ` <video loop muted autoplay playsinline src="${deerSrc}" class=videoModel__video />,`,
        }}
      />

      <div
        className={classNames('videoModel', { 'videoModel--talk': isAudioPlayed })}
        dangerouslySetInnerHTML={{
          __html: ` <video loop muted autoplay playsinline src="${deerTalkSrc}"
        class=videoModel__video />, `,
        }}
      />
    </div>
  );
};
