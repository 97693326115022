import React from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Switch, Route } from 'react-router-dom';
import { MainLayout } from '../layouts';
import 'mind-ar/dist/mindar-image.prod';
import 'aframe';
import 'mind-ar/dist/mindar-image-aframe.prod';
import '../styles/index.scss';

const HomePage = React.lazy(() => import('../pages/home'));

export const queryClient = new QueryClient();

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <MainLayout>
          <Route path="/" component={HomePage} />
        </MainLayout>
      </Switch>
    </QueryClientProvider>
  );
};
