const cdnUrl = 'https://cdn.grass-animals.ru';

export interface ObjectModelProps {
  isAudioPlayed: boolean;
}

export enum TargetModelType {
  Bars = 'bars',
  Bears = 'bears',
  Bison = 'bison',
  Deer = 'deer',
  Saiga = 'saiga',
  Tiger = 'tiger',
}

export const AudioByModel: Record<string, string> = {
  [TargetModelType.Tiger]: `${cdnUrl}/tiger.m4a`,
  [TargetModelType.Deer]: `${cdnUrl}/deer.m4a`,
  [TargetModelType.Bears]: `${cdnUrl}/bears.m4a`,
  [TargetModelType.Bison]: `${cdnUrl}/bison.m4a`,
  [TargetModelType.Bars]: `${cdnUrl}/bars.m4a`,
  [TargetModelType.Saiga]: `${cdnUrl}/saiga.m4a`,
};

export const LinkByModel: Record<string, string> = {
  [TargetModelType.Tiger]: 'https://grass.su/animals/inner-3/',
  [TargetModelType.Deer]: 'https://grass.su/animals/inner-6/',
  [TargetModelType.Bears]: 'https://grass.su/animals/inner-1/',
  [TargetModelType.Bison]: 'https://grass.su/animals/inner-5/',
  [TargetModelType.Bars]: 'https://grass.su/animals/inner-4/',
  [TargetModelType.Saiga]: 'https://grass.su/animals/inner-2/',
};

export enum ThemeType {
  Forest = 'forest',
  Ice = 'ice',
  Steppe = 'steppe',
}

export const subtitlesByModel: Record<TargetModelType, Record<number, string>> = {
  [TargetModelType.Tiger]: {
    0: 'Привет, я амурский тигр!',
    12: 'На брюхе у тигров слой \n жира в 5 см',
    30: 'Амурский тигр - в красной книге!',
    40: 'Покупая ECO CRISPI - вы экономите пластик',
    47: 'Эко-линейка имеет сертификат листок жизни',
  },
  [TargetModelType.Deer]: {
    0: 'Привет, я северный олень!',
    10: 'Роль северного оленя важна в быту некоторых народов',
    20: 'На территории России обитает около 900 тыс. особей',
    38: 'GRASS помогает Алтайскому природному заповеднику',
    48: 'Покупая ECO CRISPI - вы экономите пластик',
    57: 'Эко-линейка имеет сертификат листок жизни',
  },
  [TargetModelType.Bears]: {
    0: 'Привет, мы бурые медвежата!',
    16: 'Медвежата не могут долго прожить без мамы',
    26: 'В 2012 запретили охоту на медведя в берлоге',
    44: 'GRASS помогает Алтайскому природному заповеднику',
    54: 'Покупая ECO CRISPI - вы экономите пластик',
    65: 'Эко-линейка имеет сертификат листок жизни',
  },
  [TargetModelType.Bison]: {
    0: 'Привет, я зубр!',
    7: 'Зубр - самое крупное наземное млекопитающее Европы',
    12: 'В 1926 г. на территории России не осталось зубров',
    26: 'GRASS помогает восстановить популяцию зубров',
    38: 'Покупая ECO CRISPI - вы экономите пластик',
    47: 'Эко-линейка имеет сертификат листок жизни',
  },
  [TargetModelType.Bars]: {
    0: 'Привет, я снежный барс!',
    7: 'Высокие скалистые горы - место обитания ирбиса',
    15: 'Общая численность - от 15 до 50 особей',
    36: 'Покупая ECO CRISPI - вы экономите пластик',
    46: 'Эко-линейка имеет сертификат листок жизни',
  },
  [TargetModelType.Saiga]: {
    0: 'Привет, я сайгак!',
    4: 'Степи Евразии - место обитания сайгаков',
    15: 'Рога сайгака вырастают до 30 см в длину',
    34: 'GRASS помог питомнику “Сайгак”',
    40: 'Покупая ECO CRISPI - вы экономите пластик',
    49: 'Эко-линейка имеет сертификат листок жизни',
  },
};
