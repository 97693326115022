import React from 'react';
import { ArrowRightIcon } from '../../assets/images/icons';
import { Loader } from '../../components/loader';
import { ObjectLayoutViewProps } from './models';

import './styles.scss';

export const View = ({ model, aboutLink }: ObjectLayoutViewProps) => {
  return (
    <div className="objectLayout__wrapper">
      <Loader />

      <a href={aboutLink} target="_blank" className="objectLayout__about" rel="noreferrer">
        Подробнее
        <ArrowRightIcon className="objectLayout__about-icon" />
      </a>

      {model}
    </div>
  );
};
