import { useMemo } from 'react';
import { TigerVideo, DeerVideo, BearsVideo, BisonVideo, BarsVideo, SaigaVideo } from '../../components/objects';
import { TargetModelType, LinkByModel } from '../../models';
import { ObjectLayoutProps } from './models';
import { View } from './view';

export const ObjectLayout = ({ targetModel, isAudioPlayed }: ObjectLayoutProps) => {
  const model = useMemo(() => {
    switch (targetModel) {
      case TargetModelType.Tiger:
        return <TigerVideo isAudioPlayed={isAudioPlayed} />;

      case TargetModelType.Deer:
        return <DeerVideo isAudioPlayed={isAudioPlayed} />;

      case TargetModelType.Bears:
        return <BearsVideo isAudioPlayed={isAudioPlayed} />;

      case TargetModelType.Bison:
        return <BisonVideo isAudioPlayed={isAudioPlayed} />;

      case TargetModelType.Saiga:
        return <SaigaVideo isAudioPlayed={isAudioPlayed} />;

      case TargetModelType.Bars:
        return <BarsVideo isAudioPlayed={isAudioPlayed} />;
    }
  }, [targetModel, isAudioPlayed]);

  if (!model) {
    return null;
  }

  const aboutLink = LinkByModel[targetModel];

  return View({
    model,
    aboutLink,
  });
};
