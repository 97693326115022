import React, { Suspense } from 'react';
import { Loader } from '../../components/loader';
import './styles.scss';

export const View: React.FC = ({ children }) => {
  return (
    <Suspense fallback={<Loader />}>
      <div className="mainLayout__wrapper">
        <div className="mainLayout__inner">{children}</div>
      </div>
    </Suspense>
  );
};
